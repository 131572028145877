'use client';

import Markdown, { Props as MarkdownProps } from 'components/commercetools-ui/organisms/markdown';
import MarkdownParsed from 'components/padi-ui/markdown/markdown-parsed';
import useClassNames from 'helpers/hooks/useClassNames';
import { isNonProd } from 'helpers/utils/environment';
import { useAccount } from 'frontastic/hooks';
import { TasticProps } from '../types';

interface Props extends MarkdownProps {
  fullWidth: boolean;
  textAlign: 'center' | 'left' | 'right' | 'justify';
}

const MarkdownTastic = ({
  data: { fullWidth = false, textAlign = 'left', ...data },
  projectConfig,
}: TasticProps<Props>) => {
  const { loggedIn } = useAccount();

  const classNames = useClassNames([
    'prose px-24 pb-12 pt-24 md:px-24 md:py-12 lg:rounded-md lg:p-36 lg:px-32',
    !fullWidth ? 'mx-auto max-w-7xl' : 'w-full',
  ]);

  if (loggedIn || isNonProd()) {
    // Parse & rewrite markdown for nonprod & sso exchange links.
    return (
      <div className={classNames} style={{ textAlign }}>
        <MarkdownParsed {...data} projectConfig={projectConfig} />
      </div>
    );
  }

  return (
    <div className={classNames} style={{ textAlign }}>
      <Markdown {...data} />
    </div>
  );
};

export default MarkdownTastic;
