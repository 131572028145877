'use client';

import { Product } from 'shared/types/product/Product';
import ProductDetailsAdapter from 'components/padi-product/product-details/helpers/adapter';
import { DataSource } from 'types/datasource';
import { TasticProps } from 'frontastic/tastics/types';

const ProductDetailsTastic = ({ data, projectConfig }: TasticProps<DataSource<{ product: Product }>>) => {
  if (!data?.data?.dataSource?.product) {
    return <div className="h-auto min-h-[430px]"></div>;
  }

  return (
    <div className="h-auto min-h-[430px]">
      <ProductDetailsAdapter product={data?.data?.dataSource.product} projectConfig={projectConfig} />
    </div>
  );
};

export default ProductDetailsTastic;
