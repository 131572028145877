import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { Order } from 'shared/types/cart/Order';
import { mutate } from 'swr';
import { useFormat } from 'helpers/hooks/useFormat';
import Redirect from 'helpers/redirect';
import { useCart, useAccount } from 'frontastic';
import ThankYouHeader from './components/thank-you-header';
import FeedbackIconLayer from '../../../../commercetools-ui/atoms/button/feedbackIconLayer';
import usePurchase from '../../hooks/usePurchase';
import useTrackPage from '../../hooks/useTrack';

export interface BillingAddress {
  name: string;
  billingStreetAddressLine1: string;
  billingStreetAddressLine2: string;
  billingCity: string;
  billingState: string;
  billingPostalCode: string;
  billingCountryCode: string;
}
export interface Payment {
  authCode: string;
  expirationMonth: string;
  expirationYear: string;
  last4: string;
  piNum: string;
  ccType: string;
  paymentType: string;
}
export interface StripeSession {
  billing: BillingAddress;
  payment: Payment;
  statusMessage: string;
}

const ThankYouContent: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { transaction, data } = useCart();
  const { trackPage } = useTrackPage();
  const { loggedIn, account } = useAccount();
  const { getOrderByOrderNumber } = usePurchase();
  let order: Order = Object.assign({}, data, {
    cartId: data?.cartId || '',
    email: account?.email,
    sum: transaction.total,
    discountCodes: data?.discountCodes,
    taxed: data?.taxed,
  });

  const searchParams = useSearchParams();
  const orderNumber = searchParams.get('orderNumber')?.trim() || '';

  const getCommerceToolsOrder = async (stripeOrderNumber = '') => {
    order = await getOrderByOrderNumber(orderNumber || stripeOrderNumber);
    return order;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const commerceData = await getCommerceToolsOrder(orderNumber);
      commerceData.discountCodes = data?.discountCodes;
      console.log('non-stripe data', data);
      console.log('non-stripe purchase commerceData', commerceData);
      trackPage(commerceData ?? null);
      await mutate('/action/cart/getCart');
      setIsLoading(false);
    };
    fetchData();
  }, [orderNumber]);

  if (!loggedIn || !orderNumber) return <Redirect target="/" />;

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      {isLoading ? (
        <FeedbackIconLayer loading={isLoading} variant="ghost" />
      ) : (
        <div className="mx-auto max-w-7xl px-24 lg:px-32">
          <div className="mx-auto lg:mx-0 lg:max-w-none">
            <div className="bg-white lg:rounded-md lg:pt-36">
              <ThankYouHeader email={account?.email} onPrint={handlePrint} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ThankYouContent;
