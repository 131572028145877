import { useEffect, useState } from 'react';
import { useCart } from 'frontastic';
import CartItem from './cart-item';

const CartItemsList = () => {
  const { data } = useCart();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <div className="mt-12 divide-y divide-neutral-400 border-t border-neutral-400 lg:mt-34 lg:border-none">
      {!isLoading &&
        data?.lineItems?.map((lineItem) => (
          <div key={lineItem.lineItemId}>
            <CartItem item={lineItem} classNames={{ moveToWishlist: 'text-14' }} />
          </div>
        ))}
    </div>
  );
};

export default CartItemsList;
