import { FC } from 'react';
import { Account } from 'shared/types/account';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import PrintButton from './printButton';

type ThankYouHeaderProps = {
  email?: Account['email'];
  onPrint: (e: React.FormEvent) => void;
};

const ThankYouHeader: FC<ThankYouHeaderProps> = ({ email, onPrint }) => {
  const { formatMessage } = useFormat({ name: 'thank-you' });

  return (
    <div className="grid justify-items-start gap-32 border-neutral-400 py-24 md:border-b-0 lg:p-0">
      {/* Title section with full-width border */}
      <div className="w-full border-b border-slate-200 pb-32">
        <Typography
          as="h3"
          asSkeleton={!email}
          className={`text-3xl font-semibold text-primary-black ${email ? 'leading-tight' : 'leading-loose'}`}
        >
          {formatMessage({ id: 'thank.you', defaultMessage: 'Thank you' })}
        </Typography>
      </div>

      {/* Subtitle */}
      <div className="text-16 leading-loose text-primary-black">
        <Typography asSkeleton={!email} className="inline">
          {formatMessage({ id: 'email.sent', defaultMessage: 'An email confirmation has been sent to' })}
        </Typography>
        <span className="inline">:&nbsp;</span>
        <Typography asSkeleton={!email} className="inline font-semibold">
          {email ?? 'example@email.com'}
        </Typography>
      </div>

      {/* <PrintButton asSkeleton={!email} onPrint={onPrint} className="w-full py-8 md:w-fit md:px-68 lg:hidden" /> */}
    </div>
  );
};

export default ThankYouHeader;
