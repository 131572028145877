import { FC } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import { useFormat } from 'helpers/hooks/useFormat';
import { CartProps } from '../types';

const EmptyCart: FC<CartProps> = ({ categories, emptyStateDescription }) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const showCategories = ['courses', 'experience-level', 'course-type', 'activity-type'];

  return (
    <div className="mt-28">
      <p>
        {emptyStateDescription ??
          formatCartMessage({ id: 'cart.empty.ask', defaultMessage: 'Your cart is empty. Continue shopping?' })}
      </p>
      <ul className="mt-48 flex flex-col items-center gap-y-20 pb-8 lg:items-start">
        {categories.map(
          (category) =>
            category.depth === 0 &&
            showCategories.includes(category?.slug as string) && (
              <li key={category?.name}>
                <Link link={category?._url}>
                  <Button
                    className="w-200 rounded-md border border-primary-black text-16 text-secondary-black"
                    variant="secondary"
                  >
                    {category.name}
                  </Button>
                </Link>
              </li>
            ),
        )}
      </ul>
    </div>
  );
};

export default EmptyCart;
