/**
 * @file This is used to parse CMS markdown to rewrite urls for non-prod & sso exchange.
 */

import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import DOMPurify from 'dompurify';
import parse, { domToReact } from 'html-react-parser';
/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-ignore
import { markdown as MD } from 'markdown';
import { Log } from 'helpers/errorLogger';
import useGetUserInfo from 'helpers/hooks/useGetUserInfo';
import { switchToPadiNonProdDomain } from 'helpers/padi/domains';
import { getSsoExchangeUrl } from 'helpers/padi/sso';
import { isNonProd } from 'helpers/utils/environment';
import { ProjectConfig } from 'types/project-config';
import { useAccount } from 'frontastic';

export interface Props {
  markdown: string;
  className?: string;
  projectConfig?: ProjectConfig;
}

const MarkdownParsed: React.FC<Props> = ({ markdown, className, projectConfig }) => {
  const padiDomains = projectConfig?.padiDomains;

  const { loggedIn, getProjectConfig } = useAccount();
  const { idToken } = useGetUserInfo();
  const router = useRouter();

  const [clientId, setClientId] = useState<string | undefined>(undefined);
  const [parsedMarkdown, setParsedMarkdownn] = useState<any>();

  const handleSsoExchange = useCallback(
    async (linkUrl: string) => {
      if (!idToken || !clientId || !linkUrl) return;
      const exchange = await getSsoExchangeUrl(idToken, clientId, linkUrl);
      if (exchange) {
        router.push(exchange.redirectUri);
      } else {
        router.push(linkUrl);
      }
    },
    [idToken, clientId, router],
  );

  const getSetClientId = useCallback(async () => {
    const getClientId = await getProjectConfig('EXTENSION_COMMERCETOOLS_COGNITO_CLIENT_ID');
    if (getClientId?.setting) setClientId(getClientId.setting);
  }, [getProjectConfig]);

  useEffect(() => {
    if (loggedIn && clientId == undefined) {
      getSetClientId();
    }
  }, [clientId, getSetClientId, loggedIn]);

  useEffect(() => {
    if (typeof markdown === 'string') {
      const replace = (domNode: any) => {
        if (domNode.attribs && domNode.name == 'a') {
          if (padiDomains) {
            for (const [key, value] of Object.entries(padiDomains)) {
              let href = domNode.attribs.href;
              // Rewrite padi domains for non-prod.
              if (isNonProd()) {
                href = switchToPadiNonProdDomain(padiDomains, domNode.attribs.href);
              }
              // Handle exchange for padi domains.
              if (href.startsWith(value)) {
                if (loggedIn) {
                  return (
                    <a
                      className="hover:cursor-pointer"
                      href={href}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        handleSsoExchange(href);
                      }}
                      data-sso-exchange-app={key}
                    >
                      {domToReact(domNode.children)}
                    </a>
                  );
                }
                return <a href={href}>{domToReact(domNode.children)}</a>;
              }
            }
          }
        }
      };
      const markdownSanitized = DOMPurify.sanitize(MD.toHTML(markdown));
      const reactHtml = parse(markdownSanitized, { replace });
      setParsedMarkdownn(reactHtml);
    }
  }, [handleSsoExchange, loggedIn, markdown, padiDomains]);

  if (typeof markdown !== 'string') {
    Log.error(new Error(`Markdown: Invalid markdown property. Expected string but received ${typeof markdown}`));
    return <></>;
  }

  return (
    <>
      <div className={className}>{parsedMarkdown}</div>
    </>
  );
};

export default MarkdownParsed;
