'use client';

import React, { useMemo } from 'react';
import { Reference } from 'types/reference';
import { useSearchParams } from 'next/navigation';
import Redirect from 'helpers/redirect';
import { useAccount } from 'frontastic/hooks';
import { TasticProps } from 'frontastic/tastics/types';

export interface LoginProps {
  signInLink: Reference;
  accountLink?: Reference;
  onLogin?: () => void;
}

const AccountLoginTastic = ({ data }: TasticProps<LoginProps>) => {
  const searchParams = useSearchParams();

  const lvp = searchParams.get('lvp');

  //redirection link after user is logged in
  const redirectLink = useMemo(() => {
    const lastVisitedPage = lvp ? `/${lvp}` : data.accountLink;
    return lastVisitedPage;
  }, [data.accountLink, lvp]);

  const { loggedIn } = useAccount();

  if (loggedIn) return <Redirect target={redirectLink} />;

  return <Redirect target={data.signInLink} />;
};

export default AccountLoginTastic;
