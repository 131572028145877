import { FC, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Alert from 'components/padi-ui/alert';
import { useFormat } from 'helpers/hooks/useFormat';
import { getUserIdToken } from 'helpers/padi/cognito';
import { useCart } from 'frontastic';
import CartItemsList from './cart-items-list';
import EmptyCart from './empty-cart';
import FeedbackIconLayer from '../../../../commercetools-ui/atoms/button/feedbackIconLayer';
import { CartContentProps } from '../types';

const CartContent: FC<CartContentProps> = ({ className, ...props }) => {
  const { data, isEmpty, totalItems } = useCart();
  const { 'fe-enable-mixed-cart': ldFlagEnableMixedCart } = useFlags();
  const [idToken, setIdToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isMixedCart, setIsMixedCart] = useState(false);

  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatMessagePadi } = useFormat({ name: 'padi' });

  useEffect(() => {
    const getIdToken = getUserIdToken();
    if (getIdToken) {
      setIdToken(getIdToken);
    }

    if (data) {
      setIsMixedCart(data?.isMixedCart ?? false);
      setIsLoading(false);
    }
  }, [data]);

  return (
    <div className={className}>
      {!ldFlagEnableMixedCart && isMixedCart && (
        <Alert
          alertType="warning"
          body={`${formatMessagePadi({
            id: 'mixed.cart.alert',
            defaultMessage: `We're sorry for the inconvenience, but we can't process different types of products (e.g., eLearning, certification cards, etc.) in the same order. Please adjust your cart to continue. You can move products to your wishlist to easily find and purchase them later.`,
          })}`}
        />
      )}

      <div className="flex items-center justify-between">
        <h3 className="text-16 md:text-18 lg:text-22">
          {formatCartMessage({ id: 'cart', defaultMessage: 'Cart' })}
          {!isLoading && !isEmpty && (
            <span className="text-secondary-black">
              {`: `} {!isLoading ? totalItems : undefined} {formatCartMessage({ id: 'items', defaultMessage: 'Items' })}
            </span>
          )}
        </h3>
      </div>

      {isLoading ? (
        <div className="relative m-180 flex items-center justify-center">
          <FeedbackIconLayer loading={isLoading} variant="ghost" />
        </div>
      ) : !isEmpty ? (
        <CartItemsList />
      ) : (
        <EmptyCart {...props} />
      )}
    </div>
  );
};

export default CartContent;
