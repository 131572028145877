import { sdk } from 'sdk';

export const getSsoExchangeApps = async () => {
  const padiDomains = await sdk.callAction({ actionName: 'domain/getPadiDomains' });
  if (padiDomains.isError) return null;
  const exchangeApps = [];
  const domains = padiDomains.data as { [key: string]: string };
  for (const [key, value] of Object.entries(domains)) {
    let redirectUri = value;
    switch (key) {
      case 'www':
        redirectUri += '/padi-sso/callback';
        break;
      case 'learning':
        redirectUri += '/training/dashboard';
        break;
      case 'pro':
        redirectUri += '/me/dashboard';
        break;
      default:
        break;
    }

    exchangeApps.push({
      name: key,
      domain: value,
      redirectUri,
    });
  }

  return exchangeApps;
};

export const getSsoExchangeUrl = async (idToken: string, clientId: string, extraData: string) => {
  if (!idToken) return null;
  try {
    const exchangeEndpoint = await sdk.callAction({
      actionName: 'account/getProjectConfig',
      payload: {
        key: 'EXTENSION_COMMERCETOOLS_COGNITO_AUTH_URL',
      },
    });
    if (exchangeEndpoint.isError) return null;

    const exchangeApps = await getSsoExchangeApps();
    let redirectUri = '';
    exchangeApps?.find((app) => {
      if (extraData.startsWith(app.domain)) {
        redirectUri = app.redirectUri;
      }
    });
    const requestBody = {
      clientId,
      redirectUri,
      extraData,
    };
    const response = await fetch(`${(exchangeEndpoint.data as any).setting}/sso/exchange`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      method: 'post',
      body: JSON.stringify(requestBody),
    }).then((response) => {
      return response;
    });
    if (response.ok) {
      return response.json();
    }
  } catch (e) {
    console.error('----- e: ', e);
    return null;
  }
};
